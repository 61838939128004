<template>
    <div>
        <div class="questionnaire-top">
            <div class="questionnaire-title">{{datas.title}}</div>
            <div class="questionnaire-is-reply" v-if="datas.is_reply || datas.is_closed == 1">{{datas.is_closed === 0 ? '该问卷您已提交过' : '该问卷已失效' }}</div>
        </div>
        <div class="questionnaire-descr">
            <div class="description" v-html="datas.descr"></div>
        </div>
        <div class="question-view">
            <div class="question-field" v-for="(x,index) in datas.questions" :key="index">
                <div class="question-field-label">
                    {{index+1}}.{{x.title}}<span class="red" v-if="x.required == 1">*</span>
                </div>
                <div v-if="x.type == 3">
                    <van-field :disabled="datas.is_reply == 1 || datas.is_closed == 1" v-model="x.val" class="question-field-input" :placeholder="x.tips"/>
                </div>
                <div v-if="x.type == 1">
                    <div v-if="x.dimensions.length === 0">
                        <van-radio-group v-model="x.val" direction="horizontal">
                            <van-radio :disabled="datas.is_reply == 1 || datas.is_closed == 1" :name="idxs" v-for="(y,idxs) in x.opts" :key="idxs">{{y.name}}</van-radio>
                            <!--  <van-row class="w-parcent-100">
                                <van-col :span="24/x.opts.length" v-for="(y,idxs) in x.opts" :key="idxs">
                                    <van-radio :name="idxs">{{y.name}}</van-radio>
                                </van-col>
                            </van-row> -->
                        </van-radio-group>
                    </div>

                    <div v-if="x.dimensions.length > 0">
                        <table class="matrix-rating" cellspacing="0">
                            <tbody>
                                <tr class="trlabel">
                                    <th style="min-width: .7rem;"></th>
                                    <th v-for="(y,idx) in x.opts" :key="idx">{{y.name}}</th>
                                </tr>
                            </tbody>
                                <tbody v-for="(z,idxz) in x.dimensions" :key="idxz">
                                    <!-- <tr>
                                        <td class="text-left dimensions-name" :colspan="x.opts.length">{{z.name}}</td>
                                    </tr> -->
                                    <tr>
                                        <td>{{z.name}}</td>
                                        <td v-for="(y,idxs) in x.opts" :key="idxs">
                                            <van-radio :disabled="datas.is_reply == 1 || datas.is_closed == 1" v-model="z.val" :name="idxs"></van-radio>
                                        </td>
                                    </tr>
                                </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="height-20"></div>
        <van-button class="send-btn" type="info" :disabled="disabled" block @click="onSubmit" v-if="datas.is_reply === 0 && datas.is_closed === 0">提交</van-button>
    </div>
</template>
<script>
import {questionnaire,submit} from "@/api/customer";
import {ticket, shareTitle, shareUrl, shareImg, shareDesc, commonShare} from "@/api/wxshare";
export default {
    name:'Questionnaire',
    data() {
        return {
            params:this.$route.params,
            datas:{},
            disabled:false,
        }
    },
    created() {
        let _this = this;
        _this.updateShare();
        _this.getQuestionnaire();
    },
    methods: {
        async updateShare() {
            let _this = this;
            const res = await ticket({url:window.location.href});
            if (res.code == 1) {
                commonShare(res.data, shareTitle, window.location.href, shareImg, '天华客户满意度调查问卷')
            }
        },
        async getQuestionnaire() {
            let _this = this;
            const res = await questionnaire({uuid:_this.params.uuid});
            if (res.code == 1) {
                _this.datas = res.data;
            }
        },
        async onSubmit() {
            let _this = this;
            _this.disabled = true;
            const res = await submit({uuid:_this.params.uuid,datas:_this.datas});
            if (res.code == 1) {
                this.$toast.success(res.msg);
                _this.datas.is_reply = 1;
            } else {
                _this.disabled = false;
            }
        },
    },
}    
</script>